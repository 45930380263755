/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
import React from 'react';
import { graphql } from 'gatsby';
import BlogList from '../components/BlogList';
import Layout from '../Page';

const BlogHolder = ({
	pageContext: {
		categoryName, previousPagePath, nextPagePath },
		data: { featured: { nodes: feature }, allPosts: { nodes: posts }}
	}) => {
    return (
		<Layout>
			<BlogList
				featured={feature[0]}
				posts={posts}
				title={categoryName}
				prevLink={previousPagePath}
				nextLink={nextPagePath}
			/>
		</Layout>
    );
};

export default BlogHolder;

export const pageQuery = graphql`
	query BlogPostsByCategory($categorySlug: String!, $limit: Int!, $skip: Int!) {
		featured: allWordpressPost(
			filter: {
				categories: { elemMatch: { slug: { eq: $categorySlug } } }
			},
			sort: { fields: date, order: DESC }
			limit: 1
		) {
			nodes {
				...PostFields
			}
		}
		allPosts: allWordpressPost(
			filter: { categories: { elemMatch: { slug: { eq: $categorySlug } } } },
			sort: { fields: date, order: DESC },
			limit: $limit,
			skip: $skip
		) {		
			nodes {
				...PostFields
			}		  
		}
	}
`;